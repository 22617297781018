import React from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    FormTab,
    ImageInput,
    ReferenceInput,
    TextInput,
    FormDataConsumer,
    required,
    SelectInput,
} from 'react-admin';
import CKEditorInput from '../base/ckeditor/CKEditorInput';
import TabbedSaveForm from '../base/forms/TabbedSaveForm';
import { maxLength } from '../utils/validations';
import AutoSlugInput from '../base/input/AutoSlugInput';
import PercentageInput from '../base/input/PercentageInput';
import CurrencyInput from '../base/input/CurrencyInput';
import { DateInputField } from '../base/input/DateInput';
import makeStyles from '@mui/styles/makeStyles';
import ImageFieldInput from '../base/input/ImageFieldInput';

const useStyles = makeStyles({
    spacer: {
        marginBottom: '24px !important',
    },
});

const ProjectTabbedForm = (props) => {
    const classes = useStyles();
    return (
        <TabbedSaveForm {...props} forceConfirmation>
            <FormTab label="summary">
                <FormDataConsumer sx={{ minWidth: 265 }} label="Internal Name for Admins">
                    {({ formData, ...rest }) => {
                        return (
                            <>
                                <TextInput
                                    source="name_internal"
                                    validate={required()}
                                    helperText={formData.name ? " Project slug will be:" + formData.slug : null}
                                    {...rest}
                                />
                                <AutoSlugInput source="slug" field="name_internal" />
                            </>
                        )
                    }}
                </FormDataConsumer>
                <TextInput
                    source="name"
                    label="Marketing Name for Talent Pool"
                    validate={[required()]}
                    sx={{ minWidth: 265 }}
                />
                <FormDataConsumer sx={{ minWidth: 265 }} label="Short Name">
                    {({ formData, ...rest }) => {
                        return (
                            <>
                                <TextInput
                                    source="name_short"
                                    validate={[maxLength(25)]}
                                    helperText={formData.name_short ? " Short slug will be:" + formData.slug_short : null}
                                    {...rest}
                                />
                                <AutoSlugInput source="slug_short" field="name_short" />
                            </>
                        )
                    }}
                </FormDataConsumer>
                <ReferenceInput source="project_stage" reference="projects/stage">
                    <AutocompleteInput
                        optionText="name"
                        label="Project Stage"
                        sx={{ minWidth: 265 }}
                    />
                </ReferenceInput>
                <ReferenceInput source="project_type" reference="enums/project/ProjectType">
                    <SelectInput
                        optionText="label"
                        label="Project Type"
                        sx={{ minWidth: 265 }}
                        validate={[required()]}
                    />
                </ReferenceInput>
                <ReferenceInput
                    source="classification"
                    reference="enums/project/ProjectClassification"
                >
                    <SelectInput
                        optionText="label"
                        label="Classification"
                        sx={{ minWidth: 265 }}
                        validate={[required()]}
                    />
                </ReferenceInput>
                <DateInputField
                    source="start_date"
                    label="Start Date"
                    validate={required()}
                    className={classes.spacer}
                />
                <DateInputField
                    source="end_date"
                    label="End Date"
                    validate={required()}
                    className={classes.spacer}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <ReferenceInput source="client_profile" reference="users/client">
                            <AutocompleteInput
                                optionText="name"
                                label="Client"
                                readOnly={formData.project_type === 'extension'}
                                sx={{ minWidth: 265 }}
                                validate={[required()]}
                            />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.project_type === 'extension' && (
                            <ReferenceInput source="parent" reference="projects">
                                <AutocompleteInput
                                    style={{ minWidth: 265 }}
                                    optionText="name_internal"
                                    label="Main Project"
                                    readOnly
                                    allowEmpty
                                    filter={{
                                        'id!': formData.id,
                                        client_profile__id: formData.client_profile,
                                    }}
                                    filterToQuery={(searchText) => ({
                                        name_internal__icontains: searchText,
                                        'id!': formData.id,
                                        client_profile__id: formData.client_profile,
                                    })}
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.project_type === 'follow_up' && (
                            <ReferenceInput source="predecessor" reference="projects">
                                <AutocompleteInput
                                    label="Predecessor Project"
                                    style={{ minWidth: 265 }}
                                    optionText="name_internal"
                                    helperText="Set this for follow-up projects"
                                    allowEmpty
                                    filter={{
                                        'id!': formData.id,
                                        client_profile__id: formData.client_profile,
                                    }}
                                    filterToQuery={(searchText) => ({
                                        name_internal__icontains: searchText,
                                        'id!': formData.id,
                                        client_profile__id: formData.client_profile,
                                    })}
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>
                <ReferenceInput source="main_department" reference="workers/departments">
                    <SelectInput
                        label="Main Business Unit"
                        optionText="name"
                        variant="filled"
                        validate={required()}
                        helperText="The business unit or internal department this project is assigned to"
                    />
                </ReferenceInput>
            </FormTab>

            <FormTab label="description">
                <CKEditorInput
                    source="description"
                    label="Talent Pool Description"
                    templateParams={{
                        filter: { type: 'template.ProjectDescriptionTemplate' },
                    }}
                />
                <ImageInput source="image" label="Image" accept="image/*" multiple={false}>
                    <ImageFieldInput />
                </ImageInput>
            </FormTab>

            <FormTab label="controlling">
                <PercentageInput
                    source="target_margin"
                    label="Target Margin"
                    helperText="Only use after approval from Sales! Leave empty to use default target margin"
                />
                <CurrencyInput
                    source="margin_cost_correction"
                    label="Margin Cost Correction"
                    helperText="This value gets added to the cost when calculating the current or projected margins. Useful to factor in additional costs that we can't track on here. Negative values reduce cost (higher margin)."
                    initialValue="0.00"
                />
                <CurrencyInput
                    source="margin_revenue_correction"
                    label="Margin Revenue Correction"
                    helperText="This value gets added to the revenue when calculating the current or projected margins. Useful to factor in hardware expenses that we pay for from the project budget but pass through to the customer, effectively lowering our revenue (lower margin)."
                    initialValue="0.00"
                />
                <BooleanInput
                    source="margin_combined"
                    label="Combine margin of sub-projects"
                    fullWidth
                />
                <BooleanInput
                    source="margin_include_expenses"
                    label="Subtract expenses from the margin"
                    fullWidth
                />
                <BooleanInput
                    source="margin_projected_expenses"
                    label="Extrapolate expenses"
                    helperText="Adds projected costs of expenses, based on time progress and previous costs"
                    fullWidth
                />
                <BooleanInput
                    source="margin_projected_tasks"
                    label="Extrapolate from task hours"
                    helperText="By default planned hours on invitations are used to estimate how much work is still to be done.
                Turn this on to use open hours from tasks instead"
                    fullWidth
                />
            </FormTab>

            <FormTab label="settings">
                <BooleanInput source="timelog" label="Enable Timetracking" />
                <BooleanInput
                    source="has_ticket_obligation_for_timelogs"
                    label="Force timelogs to have Jira tickets that are connected to this project."
                />
                <BooleanInput
                    source="allow_multiple_tickets_for_timelogs"
                    label="Allow more than one Jira tickets for timelogs."
                />
                <BooleanInput source="billing" label="Enable Billing" />
            </FormTab>
        </TabbedSaveForm>
    );
};
export default ProjectTabbedForm;
